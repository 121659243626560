import { Store } from '@ngrx/store';
import { take } from 'rxjs';

import { AuthenticationActions } from '../../features/identity/features/authentication/store/authentication.actions';
import { AuthenticationSelectors } from '../../features/identity/features/authentication/store/authentication.selectors';
import { AuthenticationState } from '../../features/identity/features/authentication/store/authentication.reducer';
import { AuthenticationService } from '../../features/identity/features/authentication/services/authentication.service';
import { AuthenticatedUser } from '@eapp/clients/core';

export function authenticatedUserInitializer(
  store: Store<AuthenticationState>,
  authenticationSerivce: AuthenticationService,
) {
  const user: AuthenticatedUser | null =
    authenticationSerivce.getCachedAuthenticatedUser();

  return () =>
    new Promise<boolean>((resolve) => {
      if (user) {
        store.dispatch(
          AuthenticationActions.setAuthenticatedUser({
            authenticatedUser: user,
          }),
        );
      } else {
        store.dispatch(
          AuthenticationActions.setAuthenticatedUser({
            authenticatedUser: null,
          }),
        );
      }
      store
        .select(AuthenticationSelectors.selectAuthenticatedUser)
        .pipe(take(1))
        .subscribe((user) => resolve(true));
    });
}
