export const environment = {
  production: true,
  api: {
    protocol: 'https',
    subdomain: '',
    domain: 'api.alhamdagro.com',
    port: '',
    apiSlug: 'api/v1',
    authSlug: 'api/v1/auth',
  },
  recaptcha: {
    siteKey: '6LfGLB0iAAAAACKHwA_U6JQMtD0vYbP_P1PDjIwb',
  },
  liveChatWebSocket: {
    protocol: 'http',
    subdomain: '',
    domain: 'localhost',
    port: '7014',
    websocketSlug: 'sockets',
    hubEndpoint: 'live-chat',
  },
};
