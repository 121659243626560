import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';

import { PermissionsService } from '@eapp/admin/core/services';

import {
  ModulePermission,
  ResponseMessage,
  ResponseStatus,
} from '@eapp/clients/core';

import { PermissionsActions } from './permissions.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PermissionsEffects {
  constructor(
    private _actions: Actions,
    private _permissionsService: PermissionsService,
  ) {}

  public getAssignablePermissionRequest = createEffect(() =>
    this._actions.pipe(
      ofType(PermissionsActions.getAssignableModulePermissionsRequest),
      switchMap(() =>
        this._permissionsService.getAssignableModulePermission().pipe(
          mergeMap((permissions: ModulePermission[]) =>
            of(
              PermissionsActions.getAssignableModulePermissionsRequestSuccess({
                permissions: permissions,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              PermissionsActions.getAssignableModulePermissionsRequestFailure({
                message: {
                  status: ResponseStatus.ERROR,
                  message: error.error || 'Error searching permisisons!',
                } as ResponseMessage,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
