import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  Consignee,
  PageLaravel,
  PageRequest,
  ResponseMessage,
} from '@eapp/clients/core';

import { TableDefinition } from '@eapp/clients/datatable';
import { BasicQuerySearchFilter } from '@eapp/query-search-filters';

export const ConsigneeActions = createActionGroup({
  source: 'Consignees',
  events: {
    'Search Consignees Request': props<{
      filter: BasicQuerySearchFilter;
      pageRequest: PageRequest;
    }>(),
    'Search Consignees Request Success': props<{
      page: PageLaravel<Consignee[]>;
    }>(),
    'Search Consignees Request Failure': props<{
      message: ResponseMessage;
    }>(),
    'Create Consignee Request': props<{ consignee: Consignee }>(),
    'Create Consignee Request Success': props<{
      message: ResponseMessage;
    }>(),
    'Create Consignee Request Failure': props<{
      message: ResponseMessage;
    }>(),
    'Set Create Consignee Request Response Message': props<{
      message: ResponseMessage | null;
    }>(),
    'Get Consignee By Id Request': props<{ consigneeId: string }>(),
    'Get Consignee By Id Request Success': props<{
      consignee: Consignee | null;
    }>(),
    'Get Consignee By Id Request Failure': props<{
      message: ResponseMessage;
    }>(),
    'Set Selected Consignee': props<{ consignee: Consignee | null }>(),
    'Update Consignee Request': props<{
      consigneeId: string;
      consignee: Consignee;
    }>(),
    'Update Consignee Request Success': props<{
      message: ResponseMessage;
    }>(),
    'Update Consignee Request Failure': props<{
      message: ResponseMessage;
    }>(),
    'Set Update Consignee Request Response Message': props<{
      message: ResponseMessage | null;
    }>(),
    'Reset Selected Consignee State Slice': emptyProps(),
    'Set Consignee Table Definition': props<{
      tableDefinition: TableDefinition | null;
    }>(),
    'Reset Consignee Table Definition': emptyProps(),
    'Delete Consignee By Id Request': props<{ consigneeId: string }>(),
    'Delete Consignee By Id Request Success': props<{
      message: ResponseMessage;
    }>(),
    'Delete Consignee By Id Request Failure': props<{
      message: ResponseMessage;
    }>(),
    'Set Delete Consignee Request Response Message': props<{
      message: ResponseMessage | null;
    }>(),
  },
});
