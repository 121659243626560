import {
  ColumnDefinition,
  ColumnType,
  TableDefinition,
} from '@eapp/clients/datatable';

export const defaultConsigneeTableDefinition: TableDefinition = {
  title: 'Consignees',
  columns: [
    {
      label: 'Name',
      property: 'name',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
    {
      label: 'Address Line 1',
      property: 'address.street',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
    {
      label: 'Address Line 2',
      property: 'address.street2',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
    {
      label: 'State',
      property: 'address.state',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
    {
      label: 'City',
      property: 'address.city',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
    {
      label: 'Pincode',
      property: 'address.zip',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
    {
      label: 'Country',
      property: 'address.country',
      type: ColumnType.TEXT,
      isVisible: true,
      canModify: false,
      sortable: true,
    } as ColumnDefinition,
  ],
} as TableDefinition;

export const getDefaultConsigneeTableDefinition = (): TableDefinition => {
  return JSON.parse(
    JSON.stringify(defaultConsigneeTableDefinition),
  ) as TableDefinition;
};
