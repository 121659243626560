import { HttpErrorResponse } from '@angular/common/http';
import { isNil } from 'lodash';

type CustomErrorResponse = HttpErrorResponse & {
  message?: { message: string };
  errors?: { [key: string]: string };
};

export function getServerError(responseError: CustomErrorResponse) {
  if (isNil(responseError)) {
    return responseError;
  }
  if (responseError?.error.errors) {
    return Object.values(responseError?.error.errors || {}).join(',');
  }
  return (
    responseError.error?.message || responseError.error || responseError.message
  );
  const errorMessage =
    responseError?.message?.message ||
    responseError?.error?.message ||
    responseError?.error.errors
      ? Object.values(responseError?.error.errors || {}).join(',')
      : responseError?.error || responseError?.message;
  return typeof errorMessage === 'object'
    ? Object.keys(responseError.error)
        .flatMap((key) => responseError.error[key])
        .join(',')
    : errorMessage;
}
