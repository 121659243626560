import { inject, Injectable } from '@angular/core';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  ResponseMessage,
  ResponseStatus,
  Consignee,
  PageLaravel,
} from '@eapp/clients/core';

import { ConsigneeActions } from './consignee.actions';
import { ConsigneeService } from '../../../../../core/services/consignee/consignee.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ConsigneeEffects {
  private readonly _actions: Actions = inject(Actions);
  private readonly _consigneeService: ConsigneeService =
    inject(ConsigneeService);

  public searchConsigneesRequest = createEffect(() =>
    this._actions.pipe(
      ofType(ConsigneeActions.searchConsigneesRequest),
      switchMap(({ filter, pageRequest }) =>
        this._consigneeService.searchConsignees(filter, pageRequest).pipe(
          mergeMap((page: PageLaravel<Consignee[]>) =>
            of(
              ConsigneeActions.searchConsigneesRequestSuccess({
                page: page,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ConsigneeActions.searchConsigneesRequestFailure({
                message: {
                  status: ResponseStatus.ERROR,
                  message: error.error || 'Error searching users!',
                } as ResponseMessage,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createConsigneeRequest = createEffect(() =>
    this._actions.pipe(
      ofType(ConsigneeActions.createConsigneeRequest),
      switchMap(({ consignee }) =>
        this._consigneeService.createConsignee(consignee).pipe(
          mergeMap((response) =>
            of(
              ConsigneeActions.createConsigneeRequestSuccess({
                message: {
                  status: response.status,
                  message: response.message,
                } as ResponseMessage,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) => {
            const message: string =
              error?.status === 403
                ? 'Forbidden, you have reached your max allowed consignee!'
                : error?.error
                  ? error?.error
                  : 'Error creating new consignee!';

            return of(
              ConsigneeActions.createConsigneeRequestFailure({
                message: {
                  status: ResponseStatus.ERROR,
                  message: message,
                } as ResponseMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  public updateConsigneeRequest = createEffect(() =>
    this._actions.pipe(
      ofType(ConsigneeActions.updateConsigneeRequest),
      switchMap(({ consigneeId, consignee }) =>
        this._consigneeService.updateConsignee(consigneeId, consignee).pipe(
          mergeMap(() =>
            of(
              ConsigneeActions.updateConsigneeRequestSuccess({
                message: {
                  status: ResponseStatus.SUCCESS,
                  message: 'Successfully updated consignee account!',
                } as ResponseMessage,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ConsigneeActions.updateConsigneeRequestFailure({
                message: {
                  status: ResponseStatus.ERROR,
                  message: error.error || 'Error updating consignee account!',
                } as ResponseMessage,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getConsigneeByIdRequest = createEffect(() =>
    this._actions.pipe(
      ofType(ConsigneeActions.getConsigneeByIdRequest),
      switchMap(({ consigneeId }) =>
        this._consigneeService.getConsigneeById(consigneeId).pipe(
          mergeMap((consignee: Consignee) =>
            of(
              ConsigneeActions.getConsigneeByIdRequestSuccess({
                consignee: consignee,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ConsigneeActions.getConsigneeByIdRequestFailure({
                message: {
                  status: ResponseStatus.ERROR,
                  message: error.error || 'Error getting consignee!',
                } as ResponseMessage,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteConsigneeByIdRequest = createEffect(() =>
    this._actions.pipe(
      ofType(ConsigneeActions.deleteConsigneeByIdRequest),
      switchMap(({ consigneeId }) =>
        this._consigneeService.deleteConsigneeById(consigneeId).pipe(
          mergeMap(() =>
            of(
              ConsigneeActions.deleteConsigneeByIdRequestSuccess({
                message: {
                  status: ResponseStatus.SUCCESS,
                  message: 'Successfully deleted consignee account!',
                } as ResponseMessage,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              ConsigneeActions.deleteConsigneeByIdRequestFailure({
                message: {
                  status: ResponseStatus.ERROR,
                  message: error.error || 'Error deleting consignee!',
                } as ResponseMessage,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
