export type EappDatatableSize = 'middle' | 'small' | 'default';

export interface EappDatatableScrollConfig {
  x?: string;
  y?: string;
}

export interface EappDatatablePageSizeChanger {
  show: boolean;
  options: number[];
}

export interface EappDatatableSettings {
  bordered: boolean;
  size: EappDatatableSize;
  scroll: EappDatatableScrollConfig;
  pageSizeChanger: EappDatatablePageSizeChanger;
}

export const DEFAULT_PJO_DATATABLE_SETTINGS: EappDatatableSettings = {
  bordered: true,
  size: 'small',
  scroll: {
    y: '500px',
  },
  pageSizeChanger: {
    show: false,
    options: [10, 25, 50, 100],
  },
};
