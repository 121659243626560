import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConsigneeState, consigneeFeature } from './consignee.reducer';

export const selectConsigneeState = createFeatureSelector<ConsigneeState>(
  consigneeFeature.name,
);

export const selectConsigneesPage = createSelector(
  selectConsigneeState,
  (state: ConsigneeState) => state?.consigneePage?.elements || null,
);

export const selectCreateConsigneeResponseMessage = createSelector(
  selectConsigneeState,
  (state: ConsigneeState) => state?.createConsigneeResponseMessage || null,
);

export const selectUpdateConsigneeResponseMessage = createSelector(
  selectConsigneeState,
  (state: ConsigneeState) => state?.updateConsigneeResponseMessage || null,
);

export const selectSelectedConsignee = createSelector(
  selectConsigneeState,
  (state: ConsigneeState) => state.selectedConsignee,
);

export const selectConsigneesTableDefinition = createSelector(
  selectConsigneeState,
  (state: ConsigneeState) => state.consigneeTableDefinition,
);

export const selectDeleteConsigneeResponseMessage = createSelector(
  selectConsigneeState,
  (state: ConsigneeState) => state?.deleteConsigneeResponseMessage || null,
);

export const ConsigneesSelectors = {
  selectConsigneeState,
  selectConsigneesPage,
  selectCreateConsigneeResponseMessage,
  selectUpdateConsigneeResponseMessage,
  selectSelectedConsignee,
  selectConsigneesTableDefinition,
  selectDeleteConsigneeResponseMessage,
};
