import { makeEnvironmentProviders } from '@angular/core';

import { EnvironmentService } from './services/environment.service';
import { CACHE_SERVICE } from './services/cache-service.interface';
import { SessionCacheService } from './services/session-cache.service';
import {
  EnvironmentSettings,
  EAPP_CORE_CONFIGURATION,
} from './epp-emages-core-configuration.model';

export const provideEappCoreProviders = (environment: EnvironmentSettings) =>
  makeEnvironmentProviders([
    EnvironmentService,
    {
      provide: EAPP_CORE_CONFIGURATION,
      useValue: environment,
    },
    {
      provide: CACHE_SERVICE,
      useClass: SessionCacheService,
    },
  ]);
