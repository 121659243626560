import { UserModulePermission, UserPermission } from '../../entities';

export enum ModulePermissionNames {
  ADMINISTRATION_MODULE = 'Administration Module',
  INVOICES_MODULE = 'PI & Invoice Module',
  BRANDS_MODULE = 'Brands Module',
  BUYERS_MODULE = 'Buyers Module',
  CONSIGNEE_MODULE = 'Consignee Module',
  DASHBOARD_MODULE = 'Dashboard Module',
  PACKAGING_MODULE = 'Packaging Module',
  PRODUCTS_MODULE = 'Products Module',
  SECURITY_MODULE = 'Security Module',
}

export enum PermissionNames {
  DASHBOARD_OVERVIEW = 'Dashboard Overview',
  MANAGE_USER_ACCOUNTS = 'View User Accounts',
  CREATE_USER_ACCOUNTS = 'Create User Accounts',
  DELETE_USER_ACCOUNTS = 'Delete User Accounts',
  EDIT_USER_ACCOUNTS = 'Edit User Accounts',
  MANAGE_SECURITY_GENERAL = 'View Security General',
  MANAGE_SECURITY_PERMISSIONS = 'View Security Permissions',
  CREATE_SECURITY_PERMISSIONS = 'Create Security Permissions',
  DELETE_SECURITY_PERMISSIONS = 'Delete Security Permissions',
  EDIT_SECURITY_PERMISSIONS = 'Edit Security Permissions',
  MANAGE_SETTINGS = 'View Settings',
  MANAGE_BANK_ACCOUNTS = 'View Bank accounts',
  MANAGE_CATEGORIES = 'View Categories',
  CREATE_CATEGORIES = 'Create Categories',
  DELETE_CATEGORIES = 'Delete Categories',
  EDIT_CATEGORIES = 'Edit Categories',
  MANAGE_PRODUCT_BRANDS = 'View Product Brands',
  CREATE_PRODUCT_BRANDS = 'Create Product Brands',
  DELETE_PRODUCT_BRANDS = 'Delete Product Brands',
  EDIT_PRODUCT_BRANDS = 'Edit Product Brands',
  MANAGE_PRODUCTS = 'View Products',
  CREATE_PRODUCTS = 'Create Products',
  DELETE_PRODUCTS = 'Delete Products',
  EDIT_PRODUCTS = 'Edit Products',
  MANAGE_INVOICES = 'View Invoices',
  CREATE_INVOICES = 'Create Invoices',
  DELETE_INVOICES = 'Delete Invoices',
  EDIT_INVOICES = 'Edit Invoices',
  MANAGE_INVOICE_DATA = 'View Invoice data',
  CREATE_INVOICE_DATA = 'Create Invoice data',
  DELETE_INVOICE_DATA = 'Delete Invoice data',
  EDIT_INVOICE_DATA = 'Edit Invoice data',
  MANAGE_PROFORMA_INVOICES = 'View Proforma Invoices',
  CREATE_PROFORMA_INVOICES = 'Create Proforma Invoices',
  DELETE_PROFORMA_INVOICES = 'Delete Proforma Invoices',
  EDIT_PROFORMA_INVOICES = 'Edit Proforma Invoices',
  MANAGE_CONSIGNEES = 'View Consignees',
  CREATE_CONSIGNEES = 'Create Consignees',
  DELETE_CONSIGNEES = 'Delete Consignees',
  EDIT_CONSIGNEES = 'Edit Consignees',
  MANAGE_BUYERS = 'View Buyers',
  CREATE_BUYERS = 'Create Buyers',
  DELETE_BUYERS = 'Delete Buyers',
  EDIT_BUYERS = 'Edit Buyers',
  MANAGE_BRANDS = 'View Brands',
  CREATE_BRANDS = 'Create Brands',
  DELETE_BRANDS = 'Delete Brands',
  EDIT_BRANDS = 'Edit Brands',
  MANAGE_PACKAGINGS = 'View Packagings',
  CREATE_PACKAGINGS = 'Create Packagings',
  DELETE_PACKAGINGS = 'Delete Packagings',
  EDIT_PACKAGINGS = 'Edit Packagings',
  MANAGE_PACKING_LIST = 'View Packing List',
  CREATE_PACKING_LIST = 'Create Packing List',
  DELETE_PACKING_LIST = 'Delete Packing List',
  EDIT_PACKING_LIST = 'Edit Packing List',
}

export type UserModulePermissionsMap = {
  [key in ModulePermissionNames]: UserModulePermission;
};

export type UserPermissionsMap = {
  [key in PermissionNames]: UserPermission;
};

export interface UserModulesAndPermissionsMap {
  modules: UserModulePermissionsMap;
  permissions: UserPermissionsMap;
}

export interface UserModulePermissions {
  modules: UserModulePermission[];
}
