import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Consignee,
  EnvironmentService,
  Page,
  PageLaravel,
  PageRequest,
} from '@eapp/clients/core';
import { BasicQuerySearchFilter } from '@eapp/query-search-filters';

@Injectable({ providedIn: 'root' })
export class ConsigneeService {
  private readonly environmentService: EnvironmentService =
    inject(EnvironmentService);
  private readonly http: HttpClient = inject(HttpClient);

  public searchConsignees(
    filter: BasicQuerySearchFilter,
    pageRequest: PageRequest,
  ) {
    const queryParams: { [key: string]: string } = {
      size: pageRequest.size.toString(),
      page: pageRequest.page.toString(),
      column: pageRequest?.sort?.column?.toString() || '',
      direction: pageRequest?.sort?.direction?.toString() || '',
    };
    return this.http.get<PageLaravel<Consignee[]>>(
      `${this.environmentService.getBaseApiUrl()}/consignees/search?${filter?.query}`,
      { params: queryParams },
    );
  }

  public createConsignee(consignee: Consignee) {
    return this.http.post<{
      message: string;
      status: string;
    }>(`${this.environmentService.getBaseApiUrl()}/consignees`, consignee);
  }

  public updateConsignee(consigneeId: string, consignee: Consignee) {
    return this.http.put<{
      message: string;
      status: string;
    }>(
      `${this.environmentService.getBaseApiUrl()}/consignees/${consigneeId}`,
      consignee,
    );
  }

  public getConsigneeById(consigneeId: string) {
    return this.http.get<Consignee>(
      `${this.environmentService.getBaseApiUrl()}/consignees/${consigneeId}`,
    );
  }

  public deleteConsigneeById(consigneeId: string) {
    return this.http.delete<null>(
      `${this.environmentService.getBaseApiUrl()}/consignees/${consigneeId}`,
    );
  }
}
