import { createFeature, createReducer, on } from '@ngrx/store';

import {
  Consignee,
  Page,
  PageLaravel,
  ResponseMessage,
} from '@eapp/clients/core';
import { TableDefinition } from '@eapp/clients/datatable';

import { ConsigneeActions } from './consignee.actions';
import { getDefaultConsigneeTableDefinition } from '../pages/consignee-list/consignee-table-definition.defaults';

export interface ConsigneeState {
  consigneePage: { elements: PageLaravel<Consignee[]> } | null;
  consigneeTableDefinition: TableDefinition | null;
  createConsigneeResponseMessage: ResponseMessage | null;
  updateConsigneeResponseMessage: ResponseMessage | null;
  deleteConsigneeResponseMessage: ResponseMessage | null;
  selectedConsignee: Consignee | null;
}

export const initialConsigneeState: ConsigneeState = {
  consigneePage: null,
  consigneeTableDefinition: getDefaultConsigneeTableDefinition(),
  createConsigneeResponseMessage: null,
  updateConsigneeResponseMessage: null,
  deleteConsigneeResponseMessage: null,
  selectedConsignee: null,
};

const handleSearchConsigneesRequestSuccess = (
  state: ConsigneeState,
  { page }: { page: PageLaravel<Consignee[]> },
) => {
  return {
    ...state,
    consigneePage: { elements: page },
  } as unknown as ConsigneeState;
};

const handleCreateConsigneeRequestSuccess = (
  state: ConsigneeState,
  { message }: any,
) =>
  ({
    ...state,
    consigneePage: null,
    createConsigneeResponseMessage: message,
  }) as ConsigneeState;

const handleDeleteConsigneeRequestSuccess = (
  state: ConsigneeState,
  { message }: any,
) =>
  ({
    ...state,
    deleteConsigneeResponseMessage: message,
  }) as ConsigneeState;

const handleUpdateConsigneeRequestSuccess = (
  state: ConsigneeState,
  { message }: any,
) =>
  ({
    ...state,
    consigneePage: null,
    updateConsigneeResponseMessage: message,
  }) as ConsigneeState;

const handleGetConsigneeByUserIdRequestSuccess = (
  state: ConsigneeState,
  { consignee }: any,
) =>
  ({
    ...state,
    selectedConsignee: consignee,
  }) as ConsigneeState;

const handleSetConsigneeTableDefinition = (
  state: ConsigneeState,
  { tableDefinition }: any,
) =>
  ({
    ...state,
    consigneeTableDefinition: tableDefinition,
  }) as ConsigneeState;

const handleResetConsigneeTableDefinition = (state: ConsigneeState) =>
  ({
    ...state,
    consigneeTableDefinition: getDefaultConsigneeTableDefinition(),
  }) as ConsigneeState;

const handleResetSelectedConsigneeStateSlice = (state: ConsigneeState) =>
  ({
    ...state,
    selectedConsignee: null,
    selectedTemplateModulePermissionName: null,
    templateModulePermissionNames: null,
  }) as ConsigneeState;

export const consigneeFeature = createFeature({
  name: 'consignee',
  reducer: createReducer(
    initialConsigneeState,
    on(
      ConsigneeActions.searchConsigneesRequestSuccess,
      handleSearchConsigneesRequestSuccess,
    ),
    on(
      ConsigneeActions.createConsigneeRequestSuccess,
      ConsigneeActions.setCreateConsigneeRequestResponseMessage,
      ConsigneeActions.createConsigneeRequestFailure,
      handleCreateConsigneeRequestSuccess,
    ),
    on(
      ConsigneeActions.updateConsigneeRequestSuccess,
      ConsigneeActions.setUpdateConsigneeRequestResponseMessage,
      handleUpdateConsigneeRequestSuccess,
    ),
    on(
      ConsigneeActions.getConsigneeByIdRequestSuccess,
      ConsigneeActions.setSelectedConsignee,
      handleGetConsigneeByUserIdRequestSuccess,
    ),
    on(
      ConsigneeActions.resetSelectedConsigneeStateSlice,
      handleResetSelectedConsigneeStateSlice,
    ),
    on(
      ConsigneeActions.setConsigneeTableDefinition,
      handleSetConsigneeTableDefinition,
    ),
    on(
      ConsigneeActions.resetConsigneeTableDefinition,
      handleResetConsigneeTableDefinition,
    ),
    on(
      ConsigneeActions.deleteConsigneeByIdRequestSuccess,
      ConsigneeActions.setDeleteConsigneeRequestResponseMessage,
      ConsigneeActions.deleteConsigneeByIdRequestFailure,
      handleDeleteConsigneeRequestSuccess,
    ),
  ),
});
